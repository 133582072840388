.my-order-container {
  background-color: #f9f9f9; /* Light background for better contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

.contact-form-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.order-item {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.order-id {
  color: #007bff; /* Bootstrap primary color */
  margin-bottom: 10px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.print-order-button {
  margin-left: 10px; /* Space between the order ID and print button */
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.product-image {
  width: 100px;
  height: auto;
  border-radius: 5px;
  margin-right: 15px;
}

.product-info {
  flex-grow: 1;
}

.product-name {
  font-size: 18px;
  font-weight: 600;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.order-summary {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  overflow: visible !important;
  max-height: none !important;
}

.order-summary p {
  margin: 5px 0;
}

.search-order-input {
  width: 100% !important;
  padding: 10px;
  margin-top: 10px;
}

.MuiFormControl-root {
  width: 100% !important;
}
