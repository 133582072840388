.checkout-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.checkout-left {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.checkout-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: sticky;
  top: 20px;
  height: fit-content;
}

.checkout-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.shipping-section,
.order-details-section {
  margin-bottom: 20px;
}

.cart-products-container {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc #f9f9f9; /* Track and thumb colors for Firefox */
}

.cart-products-container::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.cart-products-container::-webkit-scrollbar-track {
  background: #f9f9f9; /* Track color */
}

.cart-products-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar thumb color */
  border-radius: 10px; /* Scrollbar thumb radius */
  border: 2px solid #f9f9f9; /* Border around thumb */
}

.cart-product-item {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.product-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
}

.product-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-pricing {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: bold;
}

.apply-coupon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.coupon-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.coupon-input-container {
  display: flex;
  gap: 10px;
  width: 100%;
}

.coupon-input {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.coupon-input:focus {
  border-color: #007bff;
  outline: none;
}

.apply-coupon-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-coupon-button:hover {
  background-color: #218838;
}

.back-div {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: bold;
}

.divider {
  margin: 10px 0;
  border-color: #ddd;
}

.back-button {
  text-align: start;
  background-color: #e69494;
}

@media screen and (max-width: 768px) {
  .checkout-container {
    flex-direction: column;
  }

  .coupon-input-container {
    flex-direction: column;
  }

  .back-div {
    flex-direction: column;
  }
  

  .checkout-right {
    position: static;
  }

  .cart-product-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .product-details {
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .product-image img {
    width: 60px;
    height: 60px;
  }
}

.user-greeting {
  font-size: 1.5rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 5px;
}

.user-name {
  font-size: 1.5rem;  /* Make the name prominent */
  font-weight: bold;
  color: black;  /* Add color to make it stand out */
  margin-bottom: 20px;
  line-height: 1.2; /* Adjust line height for better spacing */
  margin-left: 5px;
}


@media screen and (max-width: 768px) {
  .user-greeting {
    font-size: 1.2rem;
  }

  .user-name {
    font-size: 1.6rem;  /* Slightly smaller for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .user-greeting {
    font-size: 1rem;
  }

  .user-name {
    font-size: 1.4rem;
  }
}

