.about-section {
    padding: 4rem 10rem;
}

.about-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.about-para {
    font-size: 1rem;
    padding-bottom: 2rem;
    color: black;
    line-height: 1.6;
}

.about-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
}

.about-text {
    font-size: 1.2rem;
}

.about-icons {
    background: #006AA7;
    width: auto;
    margin: auto 0;
    padding: 1rem;
    border-radius: 20%;
    display: flex;
    align-items: center;
}

.about-icons img {
    max-width: 50px;
    height: auto;
    margin-right: 1rem;
}

.about-section-heading {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about-detail-para {
    font-size: 1rem;
    margin-bottom: 1rem; /* Changed from 0 to 1rem for better spacing */
    color: black;
}

.about-detail-para ul {
    list-style-type: disc;
    margin-left: 2rem;
    padding-left: 0;
}

.about-detail-para li {
    margin-bottom: 0.5rem;
}

@media (max-width: 850px) {
    .about-section {
        padding: 2rem 2rem;
    }

    .about-grid {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 850px) and (max-width: 1100px) {
    .about-section {
        padding: 2rem 3rem;
    }

    .about-grid {
        grid-template-columns: 1fr 1fr;
    }
}
